<template>
  <!-- <el-container>
    <el-header>
        <el-row :gutter="20">
            <el-col :span="6"><div class="grid-content bg-purple">加入我们</div></el-col>
        </el-row>
    </el-header>
    <el-main>
            <el-row :gutter="1">
                <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="1">姓名/单位</el-col>
                <el-col :xs="15" :sm="6" :md="8" :lg="9" :xl="11"><el-input v-model="name" placeholder="请输入内容"></el-input></el-col>
            </el-row>
            
            <el-row :gutter="1">
                <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="1">联系方式</el-col>
                <el-col :xs="15" :sm="6" :md="8" :lg="9" :xl="11"><el-input v-model="contact" placeholder="请输入内容"></el-input></el-col>
            </el-row>
            <el-row :gutter="1">
                <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="1">意向说明</el-col>
                <el-col :xs="15" :sm="6" :md="8" :lg="9" :xl="11"><el-input v-model="remark" placeholder="请输入内容"></el-input></el-col>
            </el-row>

            <el-row :gutter="1">
                 <el-col :xs="25" :sm="6" :md="4" :lg="3" :xl="1"><el-button plain style="width:100%" @click="join">加入</el-button></el-col>
            </el-row>
    </el-main>
    </el-container> -->
  <div class="index">
    <div class="img">
      <img src="../../assets/images/index.jpg" alt="" />
    </div>

    <div class="toast wid414">
      <!-- <div class="title">期待您的到来 </div> -->
      <div>
        <div><input type="text" class="width" style="padding:0 15px" v-model="name" placeholder="联系人" /></div>
        <div>
          <input type="text" class="width" style="padding:0 15px" v-model="contact" placeholder="手机号码" />
        </div>
        <div class="textarea ">
          <textarea class="text_input width" v-model="remark" placeholder="加盟意向描述"></textarea>
        </div>
      </div>
      <button @click="join" class="btn width btnWid">提交</button>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
export default {
  data() {
    return {
      name: "",
      contact: "",
      remark: "",
    };
  },
  mounted() {},
  methods: {
    join() {
      var that = this;
      this.$axios({
        url: "/joinUs/save",
        data: {
          name: that.name,
          contact: that.contact,
          remark: that.remark,
        },
        method: "post",
      }).then((res) => {
        console.log(res);
        if (res.code == 200) {
          Toast("保存成功");
          alert(1);
        } else {
          alert(2);
          Toast("44444");
          Toast(res.message);
        }
      });
    },
  },
};
</script>
<style scoped>
/* ::-webkit-input-placeholder {
  WebKit browsers
  color: black;
  font-size: 16px;
} */
/* .textarea ::-webkit-input-placeholder {
  WebKit browsers
  color: #666;
  font-size: 16px;
} */
input:focus::-webkit-input-placeholder {
  color: transparent;
}
.index {
  width: 100%;
  position: fixed;
}
img {
  width: 100vw;
  height: 100vh;
}
.toast {
  position: absolute;
  display: flex;
  width: 310px;
  margin: 0 auto;
  /* left: 15px; */
  bottom: 40px;
  border-radius: 15px;
  padding: 20px 50px 20px 20px;
  flex-direction: column;
  justify-content: space-between;
  /* background: rgba(199, 198, 198, 0.5); */
}

.title {
  margin-bottom: 15px;
  font-size: 30px;
  color: #409eff;
  text-align: center;
  /* transform: translate(-20px,0); */
}
.toast .text_input {
  width: 338px;
  height: 100px;
  padding: 10px 15px;
  box-sizing: border-box;
}
.btn {
  color: white;
  font-size: 25px;
  padding: 0;
  background: #409eff;
  border: none;
}
.width {
  width: 305px;
  height: 50px;
  margin-bottom: 15px;
}
.btnWid {
  width: 336px;
  height: 50px;
  margin-bottom: 15px;
}
@media screen and (max-width: 414px) {
  .toast .text_input {
    width: 338px;
    height: 100px;
    padding: 10px 15px;
    margin-bottom: 40px;
    box-sizing: border-box;
  }
  .wid414 {
    left: 15px;
    bottom: 80px;
  }
}
@media screen and (max-width: 375px) {
  .wid414 {
    left: -2px;
    bottom: 60px;
  }
}
@media screen and (max-width: 320px) {
  .wid414[data-v-466fa105] {
    left: -8px;
    bottom: 35px;
  }
  .width {
    width: 260px;
    height: 45px;
    margin-bottom: 15px;
  }
  .toast .text_input {
    width: 294px;
    height: 95px;
    padding: 10px 15px;
    box-sizing: border-box;
  }
  .btnWid {
    width: 293px;
    height: 50px;
    margin-bottom: 15px;
  }
}
/* body > .el-container {
    margin-bottom: 80px;
  }
 .el-header, .el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
    line-height: 80px;
    height: 100px !important

  }
   .el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;
    line-height: 50px;
  }
   .el-row {
    margin-bottom: 1px;
    
  }
  .el-col {
    border-radius: 4px;
  }
  .el-col {
    border-radius: 4px;
  }
  .bg-purple-dark {
    background: #99a9bf;
  }
  .bg-purple {
    background: #d3dce6;
  }
  .bg-purple-light {
    background: #e5e9f2;
  }
  .grid-content {
    border-radius: 4px;
    min-height: 3px;
  } */
</style>
